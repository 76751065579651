@import url('https://fonts.googleapis.com/css2?family=Sora:wght@600&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.shadow-basic {
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
}

/* h4 {
  font-size: 1.25rem !important; 
  margin: 0.5rem 0 !important;
} */

.test {
  color: yellow;
}

#edit:checked #label-edit {
  background-color: rgb(55 48 163);
}

#add:checked #label-add {
  background-color: rgb(55 48 163);
}

/* Edit Form / Edit Template Button Information */

.save-btn:hover ~ .save-information {
  opacity: 1;
  visibility: 1;
}

.save-information {
  opacity: 0;
  visibility: 0;
}

.change-order-btn:hover ~ .change-order-information {
  opacity: 1;
  visibility: 1;
}

.change-order-information {
  opacity: 0;
  visibility: 0;
}

.add-row-btn:hover ~ .add-row-information {
  opacity: 1;
  visibility: 1;
}

.add-row-information {
  opacity: 0;
  visibility: 0;
}

.add-category-btn:hover ~ .add-category-information {
  opacity: 1;
  visibility: 1;
}

.add-category-information {
  opacity: 0;
  visibility: 0;
}

.delete-btn:hover ~ .delete-information {
  opacity: 1;
  visibility: 1;
}

.delete-information {
  opacity: 0;
  visibility: 0;
}

/* Needed Fields Preview */

.neededfield-topic-btn:hover ~ .neededfield-topic-preview {
  opacity: 1;
  height: auto;
  overflow: auto;
  padding: 1em;
}

.neededfield-topic-preview {
  overflow: hidden;
  height: 0;
  opacity: 0.5;
  padding: 0;
}

.scrl-none::-webkit-scrollbar-button {
  display: none;
}

.middle-btn {
  background: conic-gradient(at 50% 100%, rgb(124, 58, 237), rgb(190, 18, 60));
}

.left-btn {
  background: rgb(190, 18, 60);
}

.right-btn {
  background: rgb(124, 58, 237);
}

.shadow-left {
  -webkit-box-shadow: -10px 0 5px -5px rgb(0 0 0 / 0.04);
  -moz-box-shadow: -10px 0 5px -5px rgb(0 0 0 / 0.04);
  box-shadow: -10px 0 5px -5px rgb(0 0 0 / 0.04);
}

.dashboard-bg {
  background: linear-gradient(135deg, rgb(248 250 252) 50%, rgb(0, 48, 91) 50%);
}

/* .cross-label {
  color: rgb(248 250 252);
} */

.bg-taxmind {
  background-color: rgb(0, 48, 91);
}

.bg-taxmin-10 {
  background-color: rgba(0, 48, 91, 0.1);
}

.level_1 {
  width: 25%;
}

.w-inherit-css {
  width: inherit;
}

/* Form */

.answer-select-menu {
  overflow: hidden;
  height: 0;
  opacity: 0;
  /* display: none; */
}

.answer-select-checkbox:checked ~ .answer-select-menu {
  opacity: 1;
  height: auto;
  overflow: auto;
  /* display: block; */
}

/* Formholder Design  */

.add-opt-btn:checked ~ .add-opt-item {
  opacity: 1;
  overflow: visible;
  /* display: block; */
}

.add-opt-item {
  overflow: hidden;
  height: 0;
  opacity: 0;
  /* display: none; */
}

/* Basic Visibility Classes  */

.visibility-field:hover ~ .visibility-item {
  opacity: 1;
  height: auto;
  overflow: auto;
}

.visibility-checkbox:checked ~ .visibility-item {
  opacity: 1;
  height: auto;
  overflow: visible;
  /* display: block; */
}

.visibility-item {
  overflow: hidden;
  height: 0;
  opacity: 0;
  /* display: none; */
}

.visibility-checkbox:checked ~ .add-property-label {
  background-color: rgb(59 130 246);
  color: white;
}

.side-menu-checkbox:checked ~ .side-menu {
  margin-left: 0;
}

.side-menu {
  position: fixed;
  z-index: 50;
  top: 0%;
  margin-left: -310px;
}

.shadow-around {
  -webkit-box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1);
  box-shadow:
    0 10px 15px -3px rgb(0 0 0 / 0.1),
    0 10px 6px -4px rgb(0 0 0 / 0.1);
}

/* Color Inputs FormHolder */

.color-container {
  display: none;
}

.color-input {
  display: none;
}

.color-input:checked ~ .color-container {
  display: block;
}

@layer components {
  /* Components */

  .component-container {
    @apply rounded-lg bg-white drop-shadow-lg p-4;
  }

  .component-icon-container {
    @apply m-1 mb-5 p-2 text-white rounded-full w-max;
  }

  .select-btn {
    @apply w-[100%] p-1 my-1 hover:text-white rounded-md transition;
  }

  /* Navigation Bar */

  .nav-container {
    @apply absolute flex flex-col bg-neutral-100 mt-3 rounded-b-lg px-2 py-2.5 text-left shadow-lg w-[14rem];
  }

  .nav-btn {
    @apply py-2 px-2.5 w-[100%] text-left my-0.5 rounded-lg flex items-center hover:bg-neutral-600 hover:text-white;
  }

  .nav-icon {
    @apply w-6 h-6 inline mr-3;
  }

  /* Select Client */

  .doc-btn {
    @apply w-[100%] py-1.5 my-1 rounded-lg hover:bg-neutral-600 hover:text-white transition-all block;
  }

  .navBtn {
    @apply hidden h-6 md:inline-flex cursor-pointer hover:scale-125 transition-all duration-150 ease-out;
  }

  .btn {
    @apply h-7 hover:scale-125 cursor-pointer transition-all duration-150 ease-out;
  }

  .blue-purple-grad {
    @apply bg-gradient-to-r from-rose-400 via-fuchsia-500 to-indigo-500;
  }

  /* Create Project / Status CSV */

  .csv-year-btn {
    @apply w-[100%] p-1 my-1 hover:bg-sky-400 hover:text-white rounded-md transition;
  }

  .reset-password-btn {
    @apply w-[100%] py-3 px-5 bg-purple-500/10 text-purple-500 border-l-4 border-transparent hover:bg-red-500/10 hover:text-red-500 hover:border-l-4 hover:border-red-500 text-left transition-all hover:px-6;
  }

  .hide-side-menu {
    @apply w-[100%] py-3 px-5 bg-white text-black border-l-4 border-transparent hover:bg-emerald-500/10 hover:text-emerald-500 hover:border-l-4 hover:border-emerald-500 text-left transition-all hover:px-6;
  }

  /* Edit Form / Template */

  .tool-btn {
    @apply text-neutral-600 border-2 rounded-full p-2 mx-1.5 hover:bg-neutral-800 hover:text-white hover:border-transparent text-neutral-500 border-neutral-400;
  }

  .info-container {
    @apply absolute bg-white text-sm rounded-lg p-2 mt-2 transition-all w-40 flex text-neutral-600;
  }

  .new-category-btn {
    @apply w-1/4 border-dashed border-2 p-2 rounded-xl my-4 border-green-800 hover:border-solid bg-green-200 mx-2;
  }

  .nav-sub-btn {
    @apply w-[100%] py-3 pr-10 pl-7 text-black border-l-4 border-transparent bg-blue-500/10 hover:text-blue-500 hover:border-blue-500 text-left transition-all hover:pl-9 hover:pr-8;
  }

  .nav-clicked {
    @apply w-[100%] py-3 border-l-4 bg-blue-500/10 text-blue-500 border-l-4 border-blue-500 text-left transition-all px-6;
  }

  .nav-logout {
    @apply w-[100%] py-3 px-5 bg-white text-black border-l-4 border-transparent hover:bg-red-500/10 hover:text-red-500 hover:border-l-4 hover:border-red-500 text-left transition-all hover:px-6;
  }

  .nav-login {
    @apply w-[100%] py-3 px-5 bg-white text-black border-l-4 border-transparent hover:bg-emerald-500/10 hover:text-emerald-500 hover:border-l-4 hover:border-emerald-500 text-left transition-all hover:px-6;
  }

  .nav-icon {
    @apply w-6 h-6 inline mr-4;
  }

  .auth-button {
    @apply px-4 py-2 text-white rounded-full mx-1 my-0.5;
  }

  /* Add User */

  .add-user-input {
    @apply w-[100%] border rounded-lg p-1 my-0.5 focus:outline-none focus:border-slate-400;
  }

  /* Color Selector */

  .color-btn {
    @apply p-2 border border-transparent hover:scale-[1.2] rounded-full;
  }
}
